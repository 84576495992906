﻿import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2'
import tippy from 'tippy.js';
import accounting from "../modules/accounting"

export default class extends Controller {

    static get targets() {
        return [

            "lblCdwByAtigTotal",
            "lblRaTotal",
            "lblSliTotal",
            "lblRlpTotal",
            "lblPaiTotal",
            "lblTotal",


            "chkCdwByAtig",
            "chkRlp",
            "chkSli",
            "chkPai",

            "rlpCoveragePP",
            "rlpCoverageA",
            "rlpCoveragePD",
            "rlpStates"
        ]
    }

    initialize() {
        console.log("initialize");
    }

    connect() {
        console.log("connect");

        this.element[this.identifier] = this

        //this.getQuoteUrl = this.data.get("url");
        this.gettingQuote = new CustomEvent("gettingq");
        this.gettingQuoteComplete = new CustomEvent("gettingqc");

        this.quoteData = window.__PAGE_DATA__.quoteData;
        this.productTypes = window.__PAGE_DATA__.productTypes;
        this.travelDetails = window.__PAGE_DATA__.travelDetails;
        this.selectedProducts = [];
        this.getStatesList();
        this.initPage();

        this.totalDue = 0.0;
        this.cdwByAtigTotal = 0.0;
        /*this.raTotal = 0.0;*/
        this.sliTotal = 0.0;
        this.rlpTotal = 0.0;
        this.paiTotal = 0.0;

        this.modalCustomClass = {
            htmlContainer: 'content is-size-6 has-text-justified',

            //popup: 'popup-class',
            //header: 'header-class',
            title: 'title is-5',
            //closeButton: 'close-button-class',
            //icon: 'icon-class',
            //image: 'image-class',
            content: 'content is-size-6 has-text-justified',
            //input: 'input-class',
            //actions: 'actions-class',
            confirmButton: 'button is-danger',
            //cancelButton: 'cancel-button-class',
            //footer: 'footer-class'
        }

        //attach tooltips
        tippy('.tippy', {
            content: function (reference) {
                const id = reference.getAttribute('data-tip');
                return $("#" + id).html();
            },
            allowHTML: true,
        });
    }

    triggerEvent(element, eventName) {
        var event = document.createEvent("HTMLEvents");
        event.initEvent(eventName, false, true);
        element.dispatchEvent(event);
    }

    //Start: Properties
    get isAnyProductAvailable() {
        if (this.quoteData &&
            this.quoteData.Success &&
            this.quoteData.Products &&
            this.quoteData.Products.length > 0) {
            return true;
        }
        return false;
    }

    get isCdwByAtigAvailable() {
        if (this.isAnyProductAvailable && this.quoteData.Products.find(({ ProductType }) => ProductType === this.productTypes.CDW_BY_ATIG.Key)) {
            return true;
        }
        return false;
    }

    get isSliAvailable() {
        if (this.isAnyProductAvailable && this.quoteData.Products.find(({ ProductType }) => ProductType === this.productTypes.SLI.Key)) {
            return true;
        }
        return false;
    }

    get isRlpAvailable() {
        if (this.isAnyProductAvailable && this.quoteData.Products.find(({ ProductType }) => ProductType === this.productTypes.RLP.Key)) {
            return true;
        }
        return false;
    }

    get isPaiAvailable() {
        if (this.isAnyProductAvailable && this.quoteData.Products.find(({ ProductType }) => ProductType === this.productTypes.PAI.Key)) {
            return true;
        }
        return false;
    }

    //End: Properties    

    initPage() {

        if (this.hasChkCdwByAtigTarget)
            this.chkCdwByAtigTarget.checked = false;

        if (this.hasChkRlpTarget) {
            this.chkRlpTarget.checked = false;
        }

        if (this.hasChkSliTarget)
            this.chkSliTarget.checked = false;

        if (this.hasChkPaiTarget)
            this.chkPaiTarget.checked = false;
    }

    showModal(event) {

        var id = event.target.getAttribute("data-p-modal");
        var modalContainer = $("#" + id);
        var modalHeading = modalContainer.find(".p-modal-header").first();
        var modalBody = modalContainer.find(".p-modal-body").first();

        Swal.fire({
            titleText: modalHeading.text(),
            html: modalBody.html(),
            customClass: this.modalCustomClass,
            confirmButtonText: "Dismiss",
            buttonsStyling: false
        })
    }

    getProduct(productType) {
        return this.quoteData.Products.find(({ ProductType }) => ProductType === productType);
    }

    selectProduct(product) {
        this.selectedProducts.push(product);
        //console.log(this.selectedProducts);
    }

    unselectProduct(product) {
        let index = this.selectedProducts.findIndex(p => p === product);
        this.selectedProducts.splice(index, 1);
        //console.log(this.selectedProducts);
    }

    //isTravellingMoreThanFiftyMiles(event) {

    //    if (event.target.checked == true) {

    //        // unselect the check box and wait for user input
    //        event.target.checked = false;
            
    //        Swal.fire({
    //            customClass: this.modalCustomClass,
    //            icon: 'question',
    //            text: 'Are you travelling more than 50 miles from your residence?',
    //            showDenyButton: true,
    //            //showCancelButton: true,
    //            confirmButtonText: 'Yes',
    //            denyButtonText: `No`,
    //        }).then((result) => {
    //            /* Read more about isConfirmed, isDenied below */
    //            if (result.isConfirmed) {                    
    //                event.target.checked = true
    //            } else if (result.isDenied) {
    //                event.target.checked = false;

    //                Swal.fire({
    //                    customClass: this.modalCustomClass,
    //                    icon: 'info',
    //                    text: 'Damage cover is available when you are travelling more than 50 miles from your residence.'
    //                });

                    
    //            }

    //            this.selectCdwByAtig(event);
    //            this.displayCdwByAtigTotal(event);
    //            this.displayTotalDue();
    //        });
    //    } else {
    //        this.selectCdwByAtig(event);
    //        this.displayCdwByAtigTotal(event);
    //        this.displayTotalDue();
    //    }
    //}

    selectSliWithRlp(event) {

        if (event.target.checked == true && this.hasChkRlpTarget && this.chkRlpTarget.checked == false) {

            // unselect the check box and wait for user input
            event.target.checked = false;

            Swal.fire({
                customClass: this.modalCustomClass,
                icon: 'question',
                text: 'SLI is not a standalone policy. Must be purchased in conjunction with RCLI, a primary liability policy. Do you want to continue?',
                showDenyButton: true,
                //showCancelButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: `No`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    event.target.checked = true
                    
                    this.selectSli(event);
                    this.displaySliTotal(event);                    

                    if (this.hasChkRlpTarget) {
                        this.chkRlpTarget.checked = true;
                        this.selectRlp(event);
                        this.displayRlpTotal(event);
                    }

                    this.displayTotalDue();                    
                    
                } else if (result.isDenied) {
                    event.target.checked = false;
                    
                    this.selectSli(event);
                    this.displaySliTotal(event);
                    this.displayTotalDue();
                }

                
            });
        } else {            
            this.selectSli(event);
            this.displaySliTotal(event);
            this.displayTotalDue();
        }

        
    }

    checkIfSliSelected(event) {
        if (event.target.checked == false) {       

            if (this.hasChkSliTarget) {
                this.chkSliTarget.checked = false;
                this.selectSli(event);
                this.displaySliTotal(event);
                this.displayTotalDue();
            }            
        }        
    }

    selectCdwByAtig(event) {
        console.log("selected");
        if (event.target.checked == true) {
            this.selectProduct(this.productTypes.CDW_BY_ATIG.Value);
        }
        else {
            this.unselectProduct(this.productTypes.CDW_BY_ATIG.Value);
        }
    }

    selectRlp(event) {
        if (event.target.checked == true) {
            this.selectProduct(this.productTypes.RLP.Value);
        }
        else {
            this.unselectProduct(this.productTypes.RLP.Value);
        }
    }

    selectSli(event) {
        if (event.target.checked == true) {
            this.selectProduct(this.productTypes.SLI.Value);
        }
        else {
            this.unselectProduct(this.productTypes.SLI.Value);
        }
    }

    selectPai(event) {
        if (event.target.checked == true) {
            this.selectProduct(this.productTypes.PAI.Value);
        }
        else {
            this.unselectProduct(this.productTypes.PAI.Value);
        }
    }

    displayCdwByAtigTotal(event) {
        if (event.target.checked == true) {
            var cdwPrice = this.getProduct(this.productTypes.CDW_BY_ATIG.Key_Numeric).TotalPrice.Value;
            this.lblCdwByAtigTotalTarget.innerHTML = accounting.formatMoney(cdwPrice);
            this.cdwByAtigTotal = cdwPrice;
        }
        else {
            this.lblCdwByAtigTotalTarget.innerHTML = accounting.formatMoney(0.0);
            this.cdwByAtigTotal = 0.0;
        }
    }

    displaySliTotal(event) {
        if (event.target.checked == true) {
            var sliPrice = this.getProduct(this.productTypes.SLI.Key_Numeric).TotalPrice.Value;
            this.lblSliTotalTarget.innerHTML = accounting.formatMoney(sliPrice);
            this.sliTotal = sliPrice;
        }
        else {
            this.lblSliTotalTarget.innerHTML = accounting.formatMoney(0.0);
            this.sliTotal = 0.0;
        }
    }

    displayRlpTotal(event) {
        if (event.target.checked == true) {
            var rlpPrice = this.getProduct(this.productTypes.RLP.Key_Numeric).TotalPrice.Value;
            this.lblRlpTotalTarget.innerHTML = accounting.formatMoney(rlpPrice);
            this.rlpTotal = rlpPrice;
        }
        else {
            this.lblRlpTotalTarget.innerHTML = accounting.formatMoney(0.0);
            this.rlpTotal = 0.0;
        }
    }

    displayPaiTotal(event) {
        if (event.target.checked == true) {
            var paiPrice = this.getProduct(this.productTypes.PAI.Key_Numeric).TotalPrice.Value;
            this.lblPaiTotalTarget.innerHTML = accounting.formatMoney(paiPrice);
            this.paiTotal = paiPrice;
        }
        else {
            this.lblPaiTotalTarget.innerHTML = accounting.formatMoney(0.0);
            this.paiTotal = 0.0;
        }
    }

    getStatesList() {
        var sel = $('#rlpStates');
        var $this = this;

        $.ajax({
            type: "GET",
            url: "api/countries/states/usa",
            cache: false,
            contentType: "application/json; charset=utf-8",
            complete: function () {

            },
            success: function (data) {
                $(data).each(function () {
                    sel.append($("<option>").attr('value', this.code).text(this.name));
                });

                /*sel.val($this.travelDetails.DestinationStateCode).change();*/

                //sel.append($("<option>").attr({ 'value': "__@", "disabled": "true" }).text("—————————————"));
                //sel.append($("<option>").attr('value', "OT").text("Other Territory"));

                ////attach onchange event
                //sel.change(function () {
                //    $("#ResidenceStateName").val(sel.find(":selected").text());
                //});
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log("error - fetching states");
            }
        });
    }

    getRlpCoverage(event) {
        var $this = this;
        console.log("change eevent fired");
        $.ajax({
            type: "GET",
            url: this.data.get("rlpUrl"),
            data: { stateCode: event.target.value },
            cache: false,
            //contentType: "application/x-www-form-urlencoded",
            success: function (data) {
                /* console.log(data);*/
                /*$this.rlpStateCodeTarget.value = event.target.value;*/

                $("#TravelDetails_DestinationStateCode").val(event.target.value);
                $("#TravelDetails_DestinationStateName").val($("#rlpStates").find(":selected").text());

                //console.log(event.target)
                //console.log(event.target.value)

                $this.displayRlpCoverage(data);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log("error occoured while getting rlp coverage data");
            }
        });

    }

    displayRlpCoverage(data) {
        /*console.log(data);*/

        this.rlpCoveragePPTarget.innerHTML = accounting.formatMoney(data.bodilyInjuryPerPerson);
        this.rlpCoverageATarget.innerHTML = accounting.formatMoney(data.bodilyInjuryAggregate);
        this.rlpCoveragePDTarget.innerHTML = accounting.formatMoney(data.propertyDamage);
    }

    displayTotalDue() {
        this.totalDue = this.cdwByAtigTotal + this.sliTotal + this.rlpTotal + this.paiTotal;
        this.lblTotalTarget.innerHTML = accounting.formatMoney(this.totalDue);
    }

    submit(event) {
        event.preventDefault();

        if (this.isFormValid()) {
            $.blockUI();
            this.addSelectedProductsToDom();
            $("#quoteForm_v2").submit();
        }
        else {
            //show error modal
            var id = "formError";
            var modalContainer = $("#" + id);
            var modalHeading = modalContainer.find(".p-modal-header").first();
            var modalBody = modalContainer.find(".p-modal-body").first();

            Swal.fire({
                icon: 'error',
                titleText: modalHeading.text(),
                html: modalBody.html(),
                customClass: this.modalCustomClass,
                confirmButtonText: "Got it!",
                buttonsStyling: false
            })
        }

    }

    isFormValid() {
        try {
            if (this.hasChkRlpTarget && this.chkRlpTarget.checked) {
                if (this.hasRlpStatesTarget && this.rlpStatesTarget.value != "")
                    return true;
                else
                    return false;
            }

            if ((this.hasChkCdwByAtigTarget && this.chkCdwByAtigTarget.checked) ||
                //(this.hasChkRaTarget && this.chkRaTarget.checked) ||
                //(this.hasRlpStatesTarget && this.rlpStatesTarget.value != "" && this.hasChkRlpTarget && this.chkRlpTarget.checked) ||
                (this.hasChkSliTarget && this.chkSliTarget.checked) ||
                (this.hasChkPaiTarget && this.chkPaiTarget.checked)) {
                return true;
            }

            return false;

        }
        catch (err) {
            console.log(err);
            return false;
        }
    }

    addSelectedProductsToDom() {
        this.selectedProducts.forEach(function (element, index, array) {
            var el = $('<input/>', {
                id: `SelectedProducts_${index}_`,
                type: "hidden",
                name: `SelectedProducts[${index}]`,
                value: element
            });

            el.appendTo("#quoteForm_v2");
        });
    }

    //TODO: move this into it's own controller
    //showLoadingModal(loadingMessage) {
    //    var finalMessage;
    //    if (loadingMessage != null) {
    //        finalMessage = loadingMessage;
    //    } else {
    //        finalMessage = "loading";
    //    }
    //    $("#loadingMessage").text(finalMessage);

    //    if (finalMessage == "loading") {
    //        $(".loading-message").css("padding-right", "90px");
    //        $(".loading-body").css("background-position-x", "calc(50% + 45px)");
    //    }
    //    if (finalMessage == "purchasing") {
    //        $(".loading-message").css("padding-right", "110px");
    //        $(".loading-body").css("background-position-x", "calc(50% + 55px)");
    //    }

    //    $('#loadingDialog').modal({
    //        backdrop: "static",
    //        keyboard: false,
    //        show: true
    //    });
    //}
}
